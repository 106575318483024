import React from 'react'
import ServicesComponent from '../components/ServicesComponent';

const Services = () => {
  return (
    <>
      <ServicesComponent />
    </>

  )
}

export default Services