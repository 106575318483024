import React from 'react'
import Generic from '../components/Generic';

const About = () => {
  return (
    <>
      <Generic />
    </>
  )
}

export default About